import "./Domain.css";
const Domain = () => {
  return (
    <a
      href="#landing"
      data-aos="fade-in"
      data-aos-duration="2000"
      data-aos-delay="2250"
      className="domain"
    >
      nhanngyn.tech
    </a>
  );
};

export default Domain;
