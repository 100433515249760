import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  text-align: center;
  li {
    position: relative;
    padding: 5px 10px;
    cursor: none;
  }
  li a {
    transition: 0.2s all linear;
    text-decoration: none;
  }
  li:hover a {
    font-weight: bold;
  }

  li:hover div {
    display: block;
  }
  div {
    display: none;
    position: absolute;
    background-color: #111;
    margin-top: 0.25rem;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: 0.2s linear all;
    text-align: left;
  }

  div a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 0.75rem;
  }

  div a:hover {
    background-color: rgb(227, 229, 196);
    color: black;
  }

  div:hover div {
    display: block;
  }

  @media (max-width: 600px) {
    flex-flow: column nowrap;
    background: linear-gradient(112.1deg, #000000 11.4%, #2d3436 70.2%);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li>
        <a className="isHover"href="#landing">home</a>
      </li>
      <li>
        <a>projects</a>
        <div>
          <a href="#simplify">simplify</a>
          <a href="#buzyfitness">buzyfitness</a>
          <a href="#gitbook">gitbook</a>
          <a href="#drawme">draw.me</a>
          {/* <a href="#noteworthy">other</a> */}
        </div>
      </li>
      <li>
        <a href="#about">about</a>
      </li>
      <li>
        <a href="#contact">contact</a>
      </li>
      <li>
        <a
          href="https://drive.google.com/file/d/1JJQJAzv3tKF7ROPnHAwiXPI3bMV5g-0m/view?usp=sharing"
          target="__blank"
        >
          resume
        </a>
      </li>
    </Ul>
  );
};

export default RightNav;
