import styles from "./About.module.css";
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faEnvira,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
const About = () => {
  return (
    <div id="about" className={styles.about}>
      <div className={styles.left}>
        <motion.div
          initial={{ opacity: 0, x: "400px" }}
          whileInView={{ opacity: 1, x: "0px" }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.container}
        >
          {" "}
          <div className={styles.cube}>
            <div className={styles.box + " " + styles.box1}>
              <FontAwesomeIcon icon={faEnvira} />
            </div>

            <div className={styles.box + " " + styles.box2}>
              <FontAwesomeIcon icon={faHtml5} />
            </div>

            <div className={styles.box + " " + styles.box3}>
              <FontAwesomeIcon icon={faCss3} />
            </div>

            <div className={styles.box + " " + styles.box4}>
              <FontAwesomeIcon icon={faReact} />
            </div>

            <div className={styles.box + " " + styles.box5}>
              <FontAwesomeIcon icon={faJsSquare} />
            </div>

            <div className={styles.box + " " + styles.box6}>
              <FontAwesomeIcon icon={faGitAlt} />
            </div>
          </div>
        </motion.div>
      </div>

      <div className={styles.right}>
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.title}
        >
          About Me
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.sub}
        >
          "Design is not just what it looks like and feels like. Design is how
          it works" - Steve Jobs
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.description}
        >
          Hey there! I am a meticulous Frontend Developer from Orange County,
          California. My favorite tech stack includes JavaScript, CSS3, MongoDB,
          MySQL. and Node.js. I'm also a huge React fanboy!
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.description}
        >
          I currently work at Altametrics, where I optimize the company's web
          core vitals by creating and maintaining intuitive, fast, and
          user-focused layouts. Altametrics provide Enterprise Labor & Inventory
          Management SaaS Solutions for restaurants worldwide.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.description}
        >
          I am also part of the instructional team at edX, a leading global
          online learning provider, for the Full Stack Development Program,
          where I utilize my technical knowledge to give back and help students
          learn the latest frameworks and technologies.
        </motion.p>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.description}
        > I am interested in
          anything coding or design related, from software engineering to UI/UX,
          and look forward to working on incredible projects with positive
          people! 
        </motion.p>
      </div>
    </div>
  );
};

export default About;
