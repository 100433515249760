import styles from "./Social.module.css";
import {
  faGithub,
  faGitlab,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa } from "@fortawesome/fontawesome-svg-core";
const Social = () => {
  return (
    <div className={styles.socials}>
      <ul
        data-aos="fade-in"
        data-aos-duration="2000"
        data-aos-delay="2250"
        className={styles.list}
      >
        <li>
          <a
            href="https://github.com/nhanng19"
            aria-label="Github"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className={styles.font}
              icon={faGithub}
              color="#fffff"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/nhanngyn/"
            aria-label="Github"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className={styles.font}
              icon={faLinkedin}
              color="#fffff"
            />
          </a>
        </li>
        <li>
          <a
            href="https://gitlab.com/nhanng19"
            aria-label="Github"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              className={styles.font}
              icon={faGitlab}
              color="#fffff"
            />
          </a>
        </li>
        <li>
          <a
            href="mailto:nhanng19@gmail.com"
            aria-label="Github"
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <i className={styles.font + " fas fa-envelope"}></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
