import Aos from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Landing from "./components/Landing/Landing";
import Navbar from "./components/NavBar/NavBar";
import Projects from "./components/Projects/Projects";
import Projects2 from "./components/Projects/Projects2";
import Projects3 from "./components/Projects/Projects3";
import Projects4 from "./components/Projects/Projects4";
import Social from "./components/Social/Social";
import Play from "./components/UI/Play";
import Domain from "./components/UI/Domain";
import Mouse from "./components/UI/Mouse";
import { useState } from "react";
import Noteworthy from "./components/Noteworthy/Noteworthy";
function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const playHandler = () => {
    const audio = document.getElementById("audio");
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    Aos.init({ duration: 500 });
    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      new Pageable("#container", {
	    animation: 750,
      delay: 100,
      events: {
          mouse: false
      },
});
`;
    document.body.append(inlineScript);
    return () => {
      inlineScript.remove();
    };
  }, []);
  return (
    <>
      <Navbar />
      <Domain />
      <Social />
      <Mouse />
      <Play isPlaying={isPlaying} playHandler={playHandler} />
      <div id="container" class="pg-container">
        <div
          data-aos="fade-in"
          data-aos-delay="2500"
          data-anchor="landing"
          id="landing"
          class="pg-page pg-active"
        >
          <Landing />
        </div>
        <div data-anchor="Simplify" id="landing2" class="pg-page">
          <Projects backgroundImage="https://i.pinimg.com/originals/92/fd/84/92fd8425e24fc1ae7b27801092977c6d.jpg" />
        </div>
        <div
          style={{ overflowX: "hidden" }}
          data-anchor="Buzyfitness"
          id="landing2"
          class="pg-page"
        >
          <Projects2 backgroundImage="https://wallpaperaccess.com/full/1087589.jpg" />
        </div>
        <div data-anchor="GitBook" id="landing3" class="pg-page">
          <Projects3 backgroundImage="https://images4.alphacoders.com/924/thumb-1920-924465.jpg" />
        </div>
        <div data-anchor="DrawMe" id="landing4" class="pg-page">
          <Projects4 backgroundImage="https://i.pinimg.com/564x/a0/e6/d8/a0e6d86a5c30ea380b53af4f2a50cd3d.jpg" />
        </div>
        {/* <div data-anchor="noteworthy"id="page-3" class="pg-page">
          <Noteworthy />
        </div> */}
        <div data-anchor="about" id="page-3" class="pg-page">
          <About />
        </div>
        <div data-anchor="contact" id="page-4" class="pg-page">
          <Contact />
        </div>
      </div>
    </>
  );
}

export default App;
