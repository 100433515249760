import "./Play.css"

const Play = (props) => {
  return (
    <div
      className="playBtn"
    >
      <i
        onClick={props.playHandler}
        className={!props.isPlaying ? "fa-solid fa-play" : "fa-solid fa-pause"}
      ></i>
    </div>
  );
};

export default Play;