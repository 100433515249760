import styles from "./Landing.module.css";
import music from "../../img/music.mp3";
import Me from "../../img/me.jpg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import Video from '../../img/second.mp4'
const Landing = () => {
  const [logo, setLogo] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLogo(true);
    }, 4000);
  }, []);
  return (
    <>
      <div className={styles.video}>
        <video
          id="myVideo"
          className={styles.video}
          autoPlay="autplay"
          muted
          loop
          src={Video}
          playsInline="playsInline"
        ></video>
      </div>
      <div id="landing" className={styles.landing}>
        <div className={styles.left}>
          {logo && <Logo />}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 4.5, duration: 1 }}
            exit="exit"
            className={styles.leftWrapper}
          >
            <h1 className={styles.name}>Nhan Nguyen</h1>
            <p>Front End Developer | Back End Developer | Freelancer</p>
          </motion.div>
        </div>
        <div className={styles.right}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 5, duration: 1 }}
            exit="exit"
            className={styles.rightWrapper}
          >
            <div>
              <i class="fa-solid fa-quote-left"></i>
            </div>
            <h2>
              Freelance <span>full stack developer</span> based in{" "}
              <span>California</span>. I help <span>clients</span> turn their
              ideas into <span>sites</span> and <span>applications</span> that
              work, <span>designed</span> and <span>developed</span> for{" "}
              <span>simple</span> and <span>intuitive</span> experiences.
            </h2>
            <img src={Me} alt="" />
          </motion.div>
        </div>
      </div>
      <video
        id="audio"
        loop
        playsinline="playsinline"
        style={{ height: "0.00001rem" }}
      >
        <source src={music} type="audio/mp3" />
      </video>
    </>
  );
};

export default Landing;
