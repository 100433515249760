import styles from "./Projects.module.css";
import simplify from "../../img/simplify.jpg";
import { motion } from "framer-motion";
const Projects = (props) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgb(0,0,0,0.8), rgb(0,0,0,0.8)), url(${props.backgroundImage})`,
      }}
      className={styles.projects}
    >
      <div className={styles.projectContainer}>
        <div className={styles.left}>
          <div className={styles.img}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              exit="exit"
              src={simplify}
              alt=""
            />
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.right}
        >
          <div className={styles.browser}>
            <div className={styles.circle1}></div>
            <div className={styles.circle2}></div>
            <div className={styles.circle3}></div>
          </div>
          <div class={styles.container}>
            <div className={styles.header}>
              <h2 class={styles.title}>Simplify</h2>
              <h4>MySQL</h4>
              <h4>Express</h4>
              <h4>Node</h4> <h4>React</h4>
            </div>
            <h3 class={styles.subTitle}>Online Article Summarizer</h3>
            <p class={styles.description}>
              Simplify is a text and article summarizer that automatically
              generates flash card, optimized for streamlining the studying
              process for students. Natural Language Processing for extracting
              Tokens and Web Scraping API to extract text from articles. 1st
              place 2022 API World Hackathon.
            </p>
            <a
              className="primary-btn"
              target="_blank"
              href="https://simplify-apiworlds.netlify.app/summarize"
              rel="noreferrer"
            >
              See live
            </a>
            <a
              className="outline"
              href="https://github.com/lam-brian/API-World"
              target="_blank"
              rel="noreferrer"
            >
              Source Code
            </a>
            <div className={styles.demo}>
              Demo: <span>tester@gmail.com</span>
              <br />
              Password: <span>password123</span>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Projects;
