import styles from "./Projects2.module.css";
import drawme from "../../img/drawme.jpg";
import { motion } from "framer-motion";
const Projects4 = (props) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgb(0,0,0,0.8), rgb(0,0,0,0.8)), url(${props.backgroundImage})`,
      }}
      className={styles.projects}
    >
      <div className={styles.projectContainer}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.left}
        >
          <div className={styles.header}>
            <h2 class={styles.title}>Draw.Me</h2>
            <h4>MySQL</h4>
            <h4>Express</h4>
            <h4>Node</h4> <h4>Handlebars</h4>
          </div>
          <h3 class={styles.subTitle}>Community Drawing Platform</h3>
          <p class={styles.description}>
            Draw.Me is your community favorite drawing platform. Pull up any
            paintings with Google Search API and sketch with Canvas'
            hardware-accelerated 2D graphics. Share your art with friends and
            comment on other users' paintings.
          </p>
          <div className={styles.button}>
            <a
              className="primary-btn"
              target="_blank"
              rel="noreferrer"
              href="https://drawme.up.railway.app/"
            >
              See live
            </a>
            <a
              className="outline"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/nhanng19/draw.me"
            >
              Source Code
            </a>
            <div className={styles.demo}>
              Demo: <span>tester@gmail.com</span>
              <br />
              Password: <span>password123</span>
            </div>
          </div>
        </motion.div>
        <div className={styles.right}>
          <div className={styles.img}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              exit="exit"
              src={drawme}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects4;
