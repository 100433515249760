import styles from "./Projects2.module.css";
import buzy from "../../img/buzy.jpg";
import { motion } from "framer-motion";
const Projects2 = (props) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgb(0,0,0,0.8), rgb(0,0,0,0.8)), url(${props.backgroundImage})`,
      }}
      className={styles.projects}
    >
      <div className={styles.projectContainer}>
        <div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.left}
        >
          <div className={styles.header}>
            <h2 class={styles.title}>BuzyFitness</h2>
            <h4>AOS</h4>
            <h4>Node</h4>
            <h4>EmailJs</h4>
            <h4>React</h4>
          </div>
          <h3 class={styles.subTitle}>Personal Training</h3>
          <p class={styles.description}>
            BuzyFitness is the top leading brand at Self Made Training Facility.
            Since deployment, BuzyFitness's online presence increased
            significantly and generated 50% more leads for the business owner.
            Built with React and employed with a newsletter subscription using
            emailJS.
          </p>
          <div className={styles.button}>
            <a
              className="primary-btn"
              target="_blank"
              rel="noreferrer"
              href="https://buzyfitness.com/"
            >
              See live
            </a>
            <a
              className="outline"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/nhanng19/train-with-intensity"
            >
              Source Code
            </a>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.img}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              exit="exit"
              src={buzy}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects2;
