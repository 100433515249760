import styles from "./Projects.module.css";
import gitbook from "../../img/gitbook.jpg";
import { motion } from "framer-motion";
const Projects3 = (props) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgb(0,0,0,0.8), rgb(0,0,0,0.8)), url(${props.backgroundImage})`,
      }}
      className={styles.projects}
    >
      <div className={styles.projectContainer}>
        <div className={styles.left}>
          <div className={styles.img}>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.75 }}
              viewport={{ once: true }}
              exit="exit"
              src={gitbook}
              alt=""
            />
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
          exit="exit"
          className={styles.right}
        >
          <div className={styles.browser}>
            <div className={styles.circle1}></div>
            <div className={styles.circle2}></div>
            <div className={styles.circle3}></div>
          </div>
          <div className={styles.container}>
            {" "}
            <div className={styles.header}>
              <h2 class={styles.title}>GitBook</h2>
              <h4>MongoDB</h4>
              <h4>Express</h4>
              <h4>Node</h4>
              <h4>React</h4>
            </div>
            <h3 class={styles.subTitle}>Content Management System</h3>
            <p class={styles.description}>
              GitBook has multiple tools for developers to organize their
              workspace. Bi-directional communication with web sockets,
              asynchronous Kanban Board, and personalized profile settings.
              GitBook serves as a one-stop collaborative space for developers
              and engineers to get work done.
            </p>
            <div className={styles.button}>
              <a
                className="primary-btn"
                target="_blank"
                rel="noreferrer"
                href="https://gitbookcms.herokuapp.com/"
              >
                See live
              </a>
              <a
                className="outline"
                target="_blank"
                rel="noreferrer"
                href="https://github.com/nhanng19/GitBook"
              >
                Source Code
              </a>
              <div className={styles.demo}>
                Demo: <span>tester@gmail.com</span>
                <br />
                Password: <span>password123</span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Projects3;
